.card-product-code {
  max-width: 30%;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  direction: rtl;
  text-align: left;
}
img.product {
  width: 100%;
}
