.display-grid {
  display: grid;
}
.onboarding {
  display: inline-block;
  list-style-position: inside;
  margin-left: -25px;
}
.onboarding.split-list {
  -moz-column-count: 2;
  -moz-column-gap: 20px;
  -webkit-column-count: 2;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 20px;
}
.edit-profile {
  position: relative;
  top: -20px;
  float: right;
}
