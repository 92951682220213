.border-box {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
calendar .header {
  float: left;
  width: 100%;
  background: var(--panel-default-heading-background-color);
  height: 40px;
}
calendar .header > * {
  height: 40px;
  line-height: 40px !important;
  display: inline-block;
  vertical-align: middle;
}
calendar .header > i {
  float: left;
  width: 40px;
  font-size: 1.125em;
  font-weight: bold;
  position: relative;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0 10px;
  cursor: pointer;
}
calendar .header > i.fa-angle-left {
  text-align: left;
}
calendar .header > i.fa-angle-right {
  text-align: right;
  margin-left: -40px;
}
calendar .header > span {
  float: left;
  width: 100%;
  font-weight: bold;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding-left: 50px;
  margin-left: -40px;
  text-align: center;
  padding-right: 40px;
  color: inherit;
}
calendar .week {
  float: left;
  width: 100%;
  border-top: 1px solid var(--alert-border-color);
}
calendar .week:first-child {
  border-top: 2px solid white;
}
calendar .week:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
calendar .week > span.day {
  float: left;
  width: 14.28571429%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-left: solid 1px var(--alert-border-color);
  font-size: 0.75em;
  height: calc((100vh - 50px - 63px - 31px - 63px) / 6);
  display: inline-block;
  vertical-align: middle;
  background: var(--panel-background-color);
  padding: 10px;
  padding-bottom: 20px;
  overflow: overlay;
  -webkit-mask-image: linear-gradient(to top, transparent 0%, black 35%);
  mask-image: linear-gradient(to top, transparent 0%, black 35%);
  -ms-overflow-style: none;
  scrollbar-width: none;
}
calendar .week > span.day::-webkit-scrollbar {
  display: none;
}
calendar .week > span.day:first-child {
  border-left: none;
}
calendar .week > span.day.today {
  background: var(--table-hover-tr-hover-background-color);
}
calendar .week > span.day.different-month {
  color: #C0C0C0;
}
calendar .week > span.day.selected {
  background: var(--panel-default-heading-background-color);
}
calendar .week > span.day > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
calendar .week.names {
  border-top: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
}
calendar .week.names > span {
  height: 40px;
  line-height: 20px;
  font-weight: bold;
  -webkit-mask-image: none;
  mask-image: none;
}
