.vignette {
  border-radius: 50%;
  display: inline-block;
  position: relative;
}
.vignette img {
  border-radius: 50%;
  display: block;
  border: 1px solid white;
}
.vignette:after {
  content: "";
  display: block;
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0) 40%, #ffffff 70%, #ffffff 110%);
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
