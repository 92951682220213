.angular-google-map-container {
  height: 500px;
}
.accreditation {
  display: flex;
  justify-content: center;
}
.accreditation img {
  max-height: 150px;
}
