.center-horizontal {
  width: 95%;
}
@media (min-width: 768px) {
  .center-horizontal {
    width: 90%;
  }
}
@media (min-width: 992px) {
  .center-horizontal {
    width: 80%;
  }
}
@media (min-width: 1200px) {
  .center-horizontal {
    width: 70%;
  }
}
.strap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  text-align: center;
}
.strap img {
  margin-bottom: 20px;
}
.strap h1,
.strap .h1 {
  font-family: 'Satisfy', cursive;
  font-size: 62px;
  font-weight: 400;
  padding: 0 20px;
  display: inline;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.strap h3,
.strap .h3 {
  padding: 10px 15px;
  display: inline;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.strap h5,
.strap .h5 {
  font-weight: 600;
  text-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}
.strap .lead {
  font-size: 24px;
}
@media (min-width: 768px) {
  .strap h1,
  .strap .h1 {
    font-size: 80px;
  }
  .strap h5,
  .strap .h5 {
    font-size: 24px;
  }
  .strap .lead {
    font-size: 30px;
  }
}
.cta-buttons {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -10%);
  z-index: 1;
  text-align: center;
}
.intro {
  margin: 0 auto 20px auto;
}
.intro .lead {
  font-size: 30px;
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (min-width: 768px) {
  .intro .lead {
    font-size: 36px;
  }
}
.title {
  top: 74%;
}
.retail {
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.retail h1,
.retail .h1 {
  margin: 4px 10px 10px 10px;
}
@media (min-width: 768px) {
  .retail h1,
  .retail .h1 {
    margin-top: 14px;
  }
}
@media (min-width: 768px) {
  .service-even {
    padding-right: 0;
  }
}
@media (min-width: 768px) {
  .service-odd {
    padding-left: 0;
  }
}
.fade-bottom {
  -webkit-mask-image: linear-gradient(to top, transparent 0%, black 15%);
  mask-image: linear-gradient(to top, transparent 0%, black 15%);
}
